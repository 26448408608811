<template>
  <main id="form">
    <section class="wrapper">
      <img
        alt="ribbon"
        class="right-ribbon"
        src="@/assets/home/right-ribbon.svg"
      />
      <img
        alt="ribbon"
        class="left-ribbon"
        src="@/assets/home/left-ribbon.svg"
      />

      <h1>
        Свържете се с нас за повече подробности и демонстрация на продукта
      </h1>

      <form>
        <Input
          type="textarea"
          label="СЪОБЩЕНИЕ"
          :disabled="loading"
          :invalid="invalid.message"
          required="true"
          v-model="form.message"
          @input="invalid.message = false"
        />
        <Input
          type="text"
          label="ВАШЕТО ИМЕ"
          :disabled="loading"
          :invalid="invalid.name"
          required="true"
          v-model="form.name"
          @input="invalid.name = false"
        />
        <Input
          type="email"
          :id="'email'"
          label="E-MAIL"
          :disabled="loading"
          :invalid="invalid.email"
          required="true"
          v-model="form.email"
          @input="invalid.email = false"
        />
        <Input
          type="phone"
          :id="'phone'"
          label="ТЕЛЕФОН"
          :disabled="loading"
          :invalid="invalid.phone"
          required="true"
          inputmode="true"
          v-model="form.phone"
          @input="invalid.phone = false"
        />

        <div class="captcha-and-button">
          <section class="captcha-wrap">
            <p>{{ `${captcha.numOne}+${captcha.numTwo}=` }}</p>
            <input
              @input="invalid.captcha = false"
              :class="{ invalid: invalid.captcha }"
              v-model="form.captchaSum"
            />
          </section>
          <Button
            @click="submit"
            :class="{ 'button--error': isInvalid, 'button--valid': isFormSend }"
            :disabled="loading"
          >
            <div
              class="send-form"
              :class="{ visible: isInvalid || loading || isFormSend }"
            >
              <p v-if="!isInvalid && !loading && !isFormSend">ИЗПРАТИ</p>
              <p v-if="loading">ИЗПРАЩАНЕ</p>
              <p v-if="isFormSend">ИЗПРАТЕНО</p>
              <p v-if="isFormSend === false || isInvalid">ГРЕШКА</p>
              <img
                v-if="!isInvalid && !loading && !isFormSend"
                src="@/assets/home/right-arrow.svg"
                alt=""
              />
              <img
                v-if="isFormSend === false || isInvalid"
                src="@/assets/home/error.svg"
                alt=""
              />
              <img v-if="loading" src="@/assets/home/loading.svg" alt="" />
              <img
                v-if="isFormSend"
                src="@/assets/home/check-mark.svg"
                alt=""
              />
            </div>
          </Button>
        </div>

        <p class="agree">
          С изпращането на това запитване вие се съгласявате с
          <span>Нашата политика за поверителност на потребителските данни</span>
        </p>
      </form>
    </section>
  </main>
</template>

<script>
import Input from "@/components/Input";
import Button from "@/components/Button";
export default {
  name: "ContractsComponent",
  components: { Input, Button },
  props: ["h1", "p"],
  computed: {
    isInvalid() {
      let errors = Object.entries(this.invalid).filter(
        ([key]) => this.invalid[key] === true
      );
      return errors.length;
    },
  },
  data() {
    return {
      loading: false,
      isFormSend: null,
      captcha: "",
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        captchaSum: "",
      },
      invalid: {
        invalid: false,
      },
    };
  },
  methods: {
    getCaptcha() {
      const numOne = Math.round(Math.random() * 20);
      const numTwo = Math.round(Math.random() * 20);

      return {
        numOne: numOne,
        numTwo: numTwo,
        sum: numOne + numTwo,
      };
    },
    isValid() {
      if (!this.form.message) {
        this.$snotify.warning("Съобщението не може да е празно");
        this.$set(this.invalid, "message", true);
        return false;
      } else if (!this.form.name) {
        this.$snotify.warning("Въведете вашите имена");
        this.$set(this.invalid, "name", true);
        return false;
      } else if (!this.form.email || !this.validateEmail(this.form.email)) {
        this.$snotify.warning("Въведете коректен имейл");
        this.$set(this.invalid, "email", true);
        return false;
      } else if (
        !this.form.phone ||
        !this.validatePhoneNumber(this.form.phone)
      ) {
        this.$snotify.warning("Въведете коректен телефон");
        this.$set(this.invalid, "phone", true);
        return false;
      } else if (!this.form.captchaSum) {
        this.$snotify.warning("Въведете captcha");
        this.$set(this.invalid, "captcha", true);
        return false;
      } else if (Number(this.form.captchaSum) !== this.captcha.sum) {
        this.$snotify.warning("Въведете валидна captcha");
        this.$set(this.invalid, "captcha", true);
        return false;
      }

      return true;
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhoneNumber(phone) {
      const re = /^[0-9 ]+$/;
      return re.test(String(phone).toLowerCase());
    },
    submit() {
      if (this.isValid()) {
        this.loading = true;
        fetch(`${process.env.VUE_APP_BASE_URI}/email/inquiry`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.form),
        })
          .then((response) => response.json())
          .then((data) => {
            this.success = data.success;
            if (data.success) {
              this.form = {
                name: "",
                email: "",
                phone: "",
                message: "",
                captchaSum: "",
              };
            }
          })
          .catch((err) => {
            console.log(err), (this.isFormSend = false);
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.$snotify.success("Изпратихте успешно запитване");
              this.isFormSend = true;
            }, 1500);

            setTimeout(() => {
              this.isFormSend = null;
            }, 3000);
          });
      }
    },
  },
  mounted() {
    this.captcha = this.getCaptcha();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
main {
  background: url("@/assets/home/form-bg.svg") no-repeat;
  background-size: cover;

  .wrapper {
    max-width: 850px;
    margin: 100px auto 50px auto;
    padding: 20px;
    position: relative;
  }
  .right-ribbon {
    position: absolute;
    right: -880px;
    top: -70px;
    z-index: 1;
  }
  .left-ribbon {
    position: absolute;
    left: -750px;
    top: 70px;
    z-index: 1;
  }

  h1 {
    margin-bottom: 80px;
    position: relative;
    z-index: 1;
  }

  form {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 410px;
    position: relative;
    z-index: 1;

    .captcha-and-button {
      display: flex;
      gap: 30px;

      .captcha-wrap {
        align-items: center;
        display: flex;
        font: normal normal bold 18px/22px Google Sans;
        padding: 0 10px;

        input {
          border: 1px dashed #000000;
          border-radius: 2px;
          width: 41px;
          outline: none;
          height: 26px;
          &.invalid {
            border: 1px solid $mainError;
          }
        }
      }

      .button {
        width: 151px;
      }
    }

    .agree {
      font: normal normal normal 12px/18px PTSerif;
      color: #3d3d3d;
      opacity: 0.8;
      span {
        text-decoration: underline;
      }
    }
  }
}
</style>