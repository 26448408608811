<template>
  <div class="home">
    <section class="home-top" v-observe-visibility="visibilityChanged">
      <div class="wrapper">
        <img alt="circle" class="circle" src="@/assets/home/circle.svg" />
        <img alt="circle-2" class="circle-2" src="@/assets/home/circle-2.svg" />
        <img
          alt="ribbon"
          class="right-ribbon"
          src="@/assets/home/right-ribbon.svg"
        />
        <img
          alt="ribbon"
          class="left-ribbon"
          src="@/assets/home/left-ribbon.svg"
        />
        <transition name="slide-top" appear>
          <header v-if="visible">
            <img alt="hr logo" src="@/assets/home/logo.svg" />
            <Button @click="scrollTo('form')">СВЪРЖЕТЕ СЕ С НАС</Button>
          </header>
        </transition>
        <transition name="appear" appear>
          <main class="home-top-content" v-if="visible">
            <div class="text-box">
              <h1>
                Програмата, която Ви показва
                <strong>къде сте в строителния процес</strong>
              </h1>
              <img alt="monitor" src="@/assets/home/monitor.png" />
              <p>
                Уеб базирано софтуерно решение за отчет и проследяване на
                строителството, механизацията, ползван инвентар и
                работата/възнаграждението на Вашите служители – на момента!
              </p>
            </div>
            <div class="monitor-div">
              <img alt="monitor" src="@/assets/home/monitor.png" />
            </div>
          </main>
        </transition>

        <transition name="slide-bottom" appear>
          <div class="learn-more" v-if="visible">
            <h1>Разберете какво може да правите с e-SMR</h1>
            <img alt="monitor" src="@/assets/home/scroll.svg" />
            <Button @click="scrollTo('form')">СВЪРЖЕТЕ СЕ С НАС</Button>
          </div>
        </transition>
      </div>
    </section>

    <section class="home-mid">
      <ImageText
        h1="Календар на Активни обекти"
        fp="Всеки един момент знаете на кой обект се работи, къде е разположена механизацията Ви!"
        sp="Планираните работници и под-изпълнители, ползван инвентар, планирани материали и дейности за деня. "
        tp="Още в началото на деня знаете какво да очаквате, а през самия ден получавате и съответните резултати."
        img="1.png"
        color="#C8E6F4"
      />
      <ImageText
        h1="Ефективно управление на проекти"
        fp="Всеки проект може да има до няколко под-нива: Проект, Обект, под-обект, част."
        sp="Така за всяка част от строителството знаете какво се влага като материали и дейности, имате пълна разбивка на разходите и успешно актувате към възложителя."
        img="2.png"
        right="true"
        color="#C8E6F4"
      />

      <ImageText
        h1="Отчитането е като на игра"
        fp="За да отчете техническия ръководител извършените СМР по обект и част от обект не е необходимо да има каквито и да е компютърни познания."
        sp="По лесно е от качването на картинки в социалните мрежи. СМР-тата му излизат в падащ списък, а той само въвежда бройката на извършеното/вложеното за обекта и готово."
        img="3.png"
        color="#C8E6F4"
      />
      <ImageText
        h1="Планиране на ресурси"
        fp="Вече знаете какво ще е необходимо по обекти като материали, механизация, инвентар и работници за следващите дни и седмици, дори и месеци. "
        sp="Вашето „ПТО“ взима информирани решения на база отчети за направени други сходни обекти."
        tp="Просто вади справката и вижда реалното изпълнение и вече знае какво ще е Ви струва това"
        img="4.png"
        right="true"
        color="#C8E6F4"
      />
      <ImageText
        h1="Сигурност и контрол"
        fp="Всеки участник в процеса има своите права и роля в системата. "
        sp="Определени хора могат да вижда цени, определени могат да правят справки. "
        tp="Тези, които отчитат обект виждат само това, което им е необходимо без възможност за грешки и достъп до информация, която не е в тяхната специалност"
        img="5.png"
        color="#C8E6F4"
      />
      <ImageText
        h1="Безкрайно подробни отчети"
        fp="От телефона ви може да видите строителния процес на момента, за точно определен проект, обект, част, работник, механизация, инвентар."
        sp="За буквално секунда получавате задълженията си към работници, подизпълнители, доставчици и то за реално вложени ресурси и изпълнение."
        img="6.png"
        right="true"
        color="#C8E6F4"
      />
      <ImageText
        h1="Автоматично актуване"
        fp="Имате възможност да актувате към възложител с един клик. "
        sp="Всичко ще бъде попълнено от програмата по даден от Вас или възложителя шаблон, точно във вида, който е необходим."
        img="7.png"
        color="#C8E6F4"
      />
    </section>
    <TheClients />
    <TheFooter id="theFooter" />
  </div>
</template>

<script>
import Button from "@/components/Button";
import ImageText from "@/components/ImageText";
import TheFooter from "@/components/TheFooter";
import TheClients from "@/components/TheClients";
export default {
  name: "HomeView",
  components: {
    Button,
    ImageText,
    TheFooter,
    TheClients,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTo(id) {
      const target = document.getElementById(id);

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({
        top: target.getBoundingClientRect().top + scrollTop,
        behavior: "smooth",
      });
    },
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.home {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .home-top {
    background: url("@/assets/home/home-bg.svg") no-repeat;
    background-size: cover;
    padding: 0 10px;

    .circle {
      position: absolute;
      left: -200px;
      top: -150px;
      z-index: 1;
    }
    .circle-2 {
      position: absolute;
      right: -600px;
      top: -450px;
      z-index: 1;
    }
    .right-ribbon {
      position: absolute;
      right: -500px;
      bottom: 60px;
      z-index: 1;
    }
    .left-ribbon {
      position: absolute;
      left: -350px;
      bottom: -70px;
      z-index: 1;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      min-height: 101vh;
      max-width: 1500px;
      margin: 0 auto;
      position: relative;
    }
    header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      position: relative;
      z-index: 1;

      .button {
        font: normal normal bold 21px/49px PTSerif;
        letter-spacing: 0px;
        @include trFast();
        &:hover {
          box-shadow: 0px 3px 12px #139dc8;
          letter-spacing: 1.26px;
        }
      }
    }
    .home-top-content {
      align-items: center;
      display: flex;
      flex-grow: 2;
      text-align: left;
      position: relative;
      padding: 0 10px;
      z-index: 1;
      div {
        width: 50%;
      }

      .text-box {
        h1 {
          font: normal normal bold 62px/70px PTSerif;
          strong {
            color: #139dc8;
            text-decoration: underline;
            text-decoration-color: #c8e6f4;
          }
        }
        img {
          display: none;
        }
        p {
          font: normal normal normal 24px/38px PTSerif;
          letter-spacing: 0.2px;
        }
      }

      .monitor-div {
        img {
          object-fit: scale-down;
          max-width: 1000px;
          width: 50vw;
        }
      }
    }
    .learn-more {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 15px;
      position: relative;
      z-index: 1;
      img {
        object-fit: scale-down;
        height: 20px;
        width: 20px;
      }
      .button {
        display: none;
      }
    }
  }
  .home-mid {
    display: flex;
    flex-direction: column;
    max-width: 1700px;
    margin: 0 auto;
  }
}
@media all and (max-width: $sm) {
  .home {
    .home-top {
      .circle-2 {
        display: none;
      }
      .right-ribbon {
        right: -1000px;
        bottom: 10px;
      }
      .left-ribbon {
        left: -600px;
        bottom: -170px;
      }

      header {
        justify-content: center;
        .button {
          display: none;
        }
      }
      .home-top-content {
        text-align: center;
        div {
          width: 100%;
        }

        .text-box {
          padding: 0 10px;
          h1 {
            font: normal normal bold 32px/38px PTSerif;
          }
          img {
            display: block;
            width: 100%;
          }
          p {
            font: normal normal normal 17px/27px PTSerif;
            letter-spacing: 0.14px;
          }
        }
        .monitor-div {
          display: none;
        }
      }
      .learn-more {
        h1,
        img {
          display: none;
        }
        .button {
          display: block;
        }
      }
    }
  }
}
</style>

