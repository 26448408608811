<template>
  <main
    class="image-text"
    :class="{ 'main-right': right }"
    v-observe-visibility="visibilityChanged"
  >
    <transition :name="isMobile ? 'fade' : 'slide-left'" appear mode="out-in">
      <div class="image" v-if="right && visible">
        <img alt="img" :src="`${require(`@/assets/home/${img}`)}`" />
      </div>
    </transition>

    <section class="text-wrapper">
      <transition
        :name="isMobile ? 'fade' : right ? 'slide-right' : 'slide-left'"
        appear
      >
        <div class="text" :style="`background:${color}`" v-if="visible">
          <section class="text-content">
            <h1>{{ h1 }}</h1>
            <p>
              {{ fp }}
            </p>
            <p>
              {{ sp }}
            </p>
            <p v-if="tp">
              {{ tp }}
            </p>
          </section>
        </div>
      </transition>
    </section>

    <transition :name="isMobile ? 'fade' : 'slide-right'" appear>
      <div class="image" v-if="!right && visible">
        <img alt="img" :src="`${require(`@/assets/home/${img}`)}`" />
      </div>
    </transition>
  </main>
</template>

<script>
export default {
  name: "ImageText",
  props: ["h1", "fp", "sp", "tp", "img", "right", "color"],
  data() {
    return {
      visible: false,
      isMobile: false,
    };
  },
  methods: {
    isMobileMode() {
      if (window.innerWidth < 1280) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    visibilityChanged(v) {
      this.visible = v;
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener("resize", this.isMobileMode);
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobileMode);
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.image-text {
  align-items: center;
  display: flex;
  text-align: left;
  justify-content: center;
  padding: 50px 10px;

  &.main-right {
    .text {
      justify-content: flex-end;
    }
    .image {
      margin: 0 -240px 0 0;
      z-index: 12;
    }
  }
  .text-wrapper {
    height: fit-content;
    min-height: 570px;
  }
  .text {
    align-items: center;
    border-radius: 50px;
    display: flex;
    height: fit-content;
    padding: 20px;
    min-height: 570px;
    .text-content {
      max-width: 70%;

      h1 {
        font: normal normal bold 48px/50px PTSerif;
        letter-spacing: 0.16px;
        padding-bottom: 20px;
      }
      p {
        font: normal normal normal 24px/36px PTSerif;
        letter-spacing: 0.2px;
        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .image {
    margin: 0 0 0 -240px;
    position: relative;
  }
}
@media all and (max-width: $sm) {
  .image-text {
    padding: 20px 0;
    text-align: center;
    flex-direction: column-reverse;

    &.main-right {
      flex-direction: column;
      .image {
        margin: 0 0 -120px 0;
      }
    }
    .text {
      align-items: center;
      border-radius: 0;
      padding: 120px 20px 20px;
      height: initial;
      .text-content {
        max-width: 100%;
      }
    }
    .image {
      margin: 0 0 -120px 0;
      img {
        width: 100%;
      }
    }
  }
}
</style>