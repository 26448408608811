<template>
  <div
    class="input-wrapper"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper--disabled': disabled,
    }"
  >
    <label>{{ label }}</label>

    <textarea
      v-if="type === 'textarea'"
      :value="value"
      :maxlength="maxlength ? maxlength : 300"
      @keypress.enter="$emit('save')"
      @input="$emit('input', $event.target.value)"
    />
    <input
      v-else
      :value="value"
      :id="id || ''"
      :inputmode="inputmode ? 'numeric' : false"
      :type="type || 'text'"
      :maxlength="maxlength ? maxlength : 100"
      :readonly="readonly ? readonly : null"
      @keypress.enter="$emit('save')"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: [
    "value",
    "type",
    "label",
    "disabled",
    "readonly",
    "maxlength",
    "invalid",
    "id",
    "inputmode",
    "prop",
  ],
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.input-wrapper {
  position: relative;
  width: 100%;
  @include tr();
  &.input-wrapper--invalid {
    label {
      color: $mainError;
    }
    input {
      border: 2px solid $mainError;
    }
  }
  &.input-wrapper--disabled {
    opacity: 0.5;
    pointer-events: none;
    @include tr();
  }
  label {
    background-color: #f6f9ff;
    font: normal normal bold 16px/20px PTSerif;
    left: 15px;
    padding: 0 10px;
    position: absolute;
    top: -9px;
    @include tr();
  }
  input,
  textarea {
    background: transparent;
    border: 0;
    color: #000000;
    font-weight: 700;
    border: 2px solid #139DC8;
    border-radius: 10px;
    font-size: $px14;
    height: 60px;
    outline: none;
    padding: 5px 10px;
    width: 100%;
    @include tr();
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000000;
      opacity: 1; /* Firefox */
    }
  }
  textarea {
    height: 150px;
    padding: 10px 5px;
    resize: none;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f6f9ff inset;
  -webkit-box-shadow: 0 0 0 30px #f6f9ff inset;
  // box-shadow: 0 0 0 30px rgb(200, 104, 19, 0.2) inset;
  // -webkit-box-shadow: 0 0 0 30px rgb(200, 104, 19, 0.2) inset;
  // -webkit-text-fill-color: white;
}
</style>