import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Snotify from 'vue-snotify'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)
Vue.use (Snotify, {
  toast: {
    position: 'rightBottom',
    showProgressBar: false,
    timeout: 3000
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
